import {
  required,
  helpers,
  maxLength,
  requiredIf,
  minValue,
  decimal
} from 'vuelidate/lib/validators';
import { coupleMails } from '@/shared/validators/couple-mails';

export const fourNumbers = helpers.regex('fourNumbers', /^[0-9]{4}$/);
export const fiveNumbers = helpers.regex('fiveNumbers', /^[0-9]{5}$/);
export const nineNumbers = helpers.regex('nineNumbers', /^[0-9]{9}$/);
export const ein = helpers.regex('ein', /^[0-9]{2}-[0-9]{7}$/);
export const ssn = helpers.regex('ssn', /^[0-9]{3}-[0-9]{2}-[0-9]{4}$/);
export const telNumber = helpers.regex(
  'telNumber',
  /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/
);
export const mail = helpers.regex(
  'mail',
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);

export const password = helpers.regex('password', /^[a-zA-Z0-9]{7,}$/);

export const companyDriverValid = {
  password: { password },
  cloudId: { nineNumbers },
  adpId: { fourNumbers },
  firstName: { required },
  lastName: { required },
  address1: { required },
  city: { required },
  zip: { required, fiveNumbers },
  cellPhone: { required, telNumber },
  homePhone: { telNumber },
  emergencyContact: { maxLength: maxLength(30) },
  emergencyPhone: { telNumber },
  email: { required, maxLength: maxLength(256), coupleMails },
  driverLicenseNumber: { required, maxLength: maxLength(20) },
  medicalNumber: { required, maxLength: maxLength(20) },
  ssn: { required, ssn },
  rate: {
    required: requiredIf(form => {
      return form.perHour;
    }),
    decimal,
    minValue: minValue(1)
  },
  hireDate: { required },
  mvrDate: { required },
  roadTestDate: { required },
  drugTestDate: { required },
  legalExpirationDate: {
    requiredIf: requiredIf(
      form => form.legalStatus !== 'Citizenship' && form.legalStatus !== 'NA'
    )
  },
  driverLicenseExpirationDate: { required },
  medicalExpirationDate: { required },
  remark: { maxLength: maxLength(1000) },
  bank: { maxLength: maxLength(100) },
  routingNumber: { maxLength: maxLength(20) },
  accountNumber: { maxLength: maxLength(30) },
  maritalStatus: { required },
  legalStatus: { required },
  race: { required }
};

export const otherDriverValid = {
  password: { password },
  contractDate: { required },
  ein: { required: requiredIf(form => !form.ssn), ein },
  firstName: { required },
  lastName: { required },
  address1: { required },
  city: { required },
  zip: { required, fiveNumbers },
  cellPhone: { required, telNumber },
  homePhone: { telNumber },
  emergencyContact: { maxLength: maxLength(30) },
  emergencyPhone: { telNumber },
  email: { required, mail },
  driverLicenseNumber: { maxLength: maxLength(20) },
  legalName: { maxLength: maxLength(100) },
  truckYear: { fourNumbers },
  medicalNumber: { maxLength: maxLength(20) },
  ssn: { required: requiredIf(form => !form.ein), ssn },
  insuranceNumber: { required },
  insuranceCompany: { required },
  driverLicenseExpirationDate: {},
  medicalExpirationDate: {},
  insuranceExpirationDate: { required },
  remark: { maxLength: maxLength(1000) },
  bank: { maxLength: maxLength(100) },
  routingNumber: { maxLength: maxLength(20) },
  accountNumber: { maxLength: maxLength(30) }
};

export const onlyCompDriverData = [
  'cloudId',
  'adpId',
  'legalStatus',
  'nationality',
  'perHour',
  'perLoad',
  'rate',
  'rehireDate',
  'hireDate',
  'legalExpirationDate',
  'mvrDate',
  'roadTestDate',
  'drugTestDate'
];

export const onlyCompFilesDriverData = [
  'MVR',
  'ROAD_TEST',
  'DRUG_TEST',
  'TAX_FORM',
  'I9',
  'W4',
  'ANNUAL_VIOLATION',
  '10_YEARS',
  'OTHER'
];

export const onlyOtherDriverData = [
  'company',
  'contractDate',
  'type',
  'ein',
  'mc',
  'dot',
  'truckMaker',
  'truckModel',
  'truckYear',
  'plate',
  'truckState',
  'vin',
  'insuranceNumber',
  'insuranceExpirationDate',
  'insuranceCompany'
];

export const onlyOtherFilesDriverData = ['INSURANCE', 'W9'];

export const compDriverData = {
  cloudId: null, //c
  adpId: null, //c
  rehireDate: null, //c
  legalStatus: 'Citizenship', //c
  nationality: 'United States', //c
  legalExpirationDate: null, //c
  mvrDate: null, //c
  roadTestDate: null, //c
  drugTestDate: null, //c
  perHour: false, //c
  perLoad: false, //c
  rate: '', //c
  division: null, //
  position: 'Company', //
  tabletId: null, //
  password: null, //
  hireDate: null, //
  terminateDate: null, //
  active: true, //
  firstName: null, //
  middleName: null, //
  lastName: null, //
  gender: 'Female', //
  birthday: null, //
  address1: null, //
  address2: null, //
  city: null, //
  state: 'AL', //
  zip: null, //
  country: 'United States', //
  cellPhone: null, //
  homePhone: null, //
  email: null, //
  maritalStatus: 'Single', //
  tobacco: true, //
  race: 'Asian', //
  emergencyContact: null, //
  emergencyPhone: null, //
  remark: null, //
  bank: null, //
  bankType: null, //
  routingNumber: null, //
  accountNumber: null, //
  driverLicenseNumber: null, //
  driverLicenseExpirationDate: null, //
  haz: false, //
  medicalNumber: null, //
  medicalExpirationDate: null, //
  ssn: null, //
  twicExpirationDate: null, //
  files: [
    {
      //
      category: 'PHOTO', //0
      documentName: null,
      filename: null,
      body: null,
      tempS3FileName: '',
      tempS3PreSignedUrl: ''
    },
    {
      //
      category: 'BANK',
      documentName: null,
      filename: null,
      body: null,
      tempS3FileName: '',
      tempS3PreSignedUrl: ''
    },
    {
      //
      category: 'VOID_CHECK', //2
      documentName: null,
      filename: null,
      body: null,
      tempS3FileName: '',
      tempS3PreSignedUrl: ''
    },
    {
      //==
      category: 'DRIVER_LICENSE',
      documentName: null,
      filename: null,
      body: null,
      tempS3FileName: '',
      tempS3PreSignedUrl: ''
    },
    {
      //==
      category: 'MEDICAL', //4
      documentName: null,
      filename: null,
      body: null,
      tempS3FileName: '',
      tempS3PreSignedUrl: ''
    },
    {
      //==
      category: 'SSN',
      documentName: null,
      filename: null,
      body: null,
      tempS3FileName: '',
      tempS3PreSignedUrl: ''
    },
    {
      //
      category: 'TWIC', //6
      documentName: null,
      filename: null,
      body: null,
      tempS3FileName: '',
      tempS3PreSignedUrl: ''
    },
    {
      //==
      category: 'APPLICATION',
      documentName: null,
      filename: null,
      body: null,
      tempS3FileName: '',
      tempS3PreSignedUrl: ''
    },
    {
      //==c
      category: 'MVR', //8
      documentName: null,
      filename: null,
      body: null,
      tempS3FileName: '',
      tempS3PreSignedUrl: ''
    },
    {
      //==c
      category: 'ROAD_TEST',
      documentName: null,
      filename: null,
      body: null,
      tempS3FileName: '',
      tempS3PreSignedUrl: ''
    },
    {
      //==c
      category: 'DRUG_TEST', //10
      documentName: null,
      filename: null,
      body: null,
      tempS3FileName: '',
      tempS3PreSignedUrl: ''
    },
    {
      //==c
      category: 'TAX_FORM',
      documentName: null,
      filename: null,
      body: null,
      tempS3FileName: '',
      tempS3PreSignedUrl: ''
    },
    {
      //==c
      category: 'I9', //12
      documentName: null,
      filename: null,
      body: null,
      tempS3FileName: '',
      tempS3PreSignedUrl: ''
    },
    {
      //==c
      category: 'W4',
      documentName: null,
      filename: null,
      body: null,
      tempS3FileName: '',
      tempS3PreSignedUrl: ''
    },
    {
      //c
      category: 'ANNUAL_VIOLATION', //14
      documentName: null,
      filename: null,
      body: null,
      tempS3FileName: '',
      tempS3PreSignedUrl: ''
    },
    {
      //c
      category: '10_YEARS',
      documentName: null,
      filename: null,
      body: null,
      tempS3FileName: '',
      tempS3PreSignedUrl: ''
    },
    {
      //c
      category: 'OTHER', //16
      documentName: null,
      filename: null,
      body: null,
      tempS3FileName: '',
      tempS3PreSignedUrl: ''
    }
  ]
};

export const otherDriverData = {
  company: null, //o
  contractDate: null, //o
  type: 'IND/SOLE', //o
  ein: null, //o
  mc: null, //o
  dot: null, //o
  truckMaker: null, //o
  truckModel: null, //o
  truckYear: null, //o
  plate: null, //o
  truckState: 'AL', //o
  vin: null, //o
  insuranceNumber: null, //o
  insuranceExpirationDate: null, //o
  insuranceCompany: null, //o
  division: null, //
  position: '', //
  tabletId: null, //
  password: null, //
  terminateDate: null, //
  active: true, //
  firstName: null, //
  middleName: null, //
  lastName: null, //
  gender: 'Female', //
  birthday: null, //
  address1: null, //
  address2: null, //
  city: null, //
  state: 'AL', //
  zip: null, //
  country: 'United States', //
  cellPhone: null, //
  homePhone: null, //
  email: null, //
  maritalStatus: 'Single', //
  tobacco: true, //
  race: 'Asian', //
  emergencyContact: null, //
  emergencyPhone: null, //
  remark: null, //
  bank: null, //
  bankType: null, //
  routingNumber: null, //
  accountNumber: null, //
  driverLicenseNumber: null, //
  driverLicenseExpirationDate: null, //
  haz: false, //
  medicalNumber: null, //
  medicalExpirationDate: null, //
  ssn: null, //
  twicExpirationDate: null, //
  legalName: null,
  files: [
    {
      //
      category: 'PHOTO', //0
      documentName: null,
      filename: null,
      body: null,
      tempS3FileName: '',
      tempS3PreSignedUrl: ''
    },
    {
      //
      category: 'BANK',
      documentName: null,
      filename: null,
      body: null,
      tempS3FileName: '',
      tempS3PreSignedUrl: ''
    },
    {
      //
      category: 'VOID_CHECK', //2
      documentName: null,
      filename: null,
      body: null,
      tempS3FileName: '',
      tempS3PreSignedUrl: ''
    },
    {
      //==
      category: 'DRIVER_LICENSE',
      documentName: null,
      filename: null,
      body: null,
      tempS3FileName: '',
      tempS3PreSignedUrl: ''
    },
    {
      //==
      category: 'MEDICAL', //4
      documentName: null,
      filename: null,
      body: null,
      tempS3FileName: '',
      tempS3PreSignedUrl: ''
    },
    {
      //==
      category: 'SSN',
      documentName: null,
      filename: null,
      body: null,
      tempS3FileName: '',
      tempS3PreSignedUrl: ''
    },
    {
      //
      category: 'TWIC', //6
      documentName: null,
      filename: null,
      body: null,
      tempS3FileName: '',
      tempS3PreSignedUrl: ''
    },
    {
      //==
      category: 'APPLICATION',
      documentName: null,
      filename: null,
      body: null,
      tempS3FileName: '',
      tempS3PreSignedUrl: ''
    },
    {
      //==o
      category: 'INSURANCE', //8
      documentName: null,
      filename: null,
      body: null,
      tempS3FileName: '',
      tempS3PreSignedUrl: ''
    },
    {
      //==o
      category: 'W9',
      documentName: null,
      filename: null,
      body: null,
      tempS3FileName: '',
      tempS3PreSignedUrl: ''
    }
  ]
};
